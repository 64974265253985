/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { envInfo } from '../constants/constants.js';

const API_URL = envInfo.apiurl;

axios.interceptors.response.use(null, error => {
  const errorCode = error.response?.status ?? 0;
  if (errorCode >= 500) {
    Sentry.captureException(error);
  }
  return Promise.reject(error);
});

axios.defaults.validateStatus = function (status) {
  return status < 400; // Resolve only if the status code is less than 400
};

export async function placeTestRequest(payload) {
  let loader = true;

  // check after 15 secs to see if the api has returned, loader will be false
  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);
  return axios
    .post(`${API_URL}/patient-test`, payload)
    .then(function (res) {
      loader = false;
      return res;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error(err);
    });
}

export async function fetchPaymentIntent(payload) {
  return axios
    .post(`${API_URL}/payment-intent`, payload)
    .then(function (res) {
      return res.data;
    })
    .catch(function (err) {
      console.error('error: ', err);
      return Promise.reject(err.response.data.error);
    });
}

export async function fetchPaymentDetails(requisitionId) {
  const token = localStorage.getItem('access_token');
  return axios
    .get(`${API_URL}/payment/${requisitionId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      return res.data;
    })
    .catch(function (err) {
      console.error('error: ', err);
      return Promise.reject(err.response.data.error);
    });
}

export async function fetchAllConsents(payload) {
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);

  return axios
    .get(`${API_URL}/consent?consents=${payload}`)
    .then(function (res) {
      loader = false;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error('error: ', err);
      return err;
    });
}

export async function fetchUsersConsents() {
  const token = localStorage.getItem('access_token');
  const userId = localStorage.getItem('user_id');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);
  return axios
    .get(`${API_URL}/patient/${userId}/consent`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error('error: ', err);
      return err;
    });
}

export async function fetchUserID() {
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);
  return axios
    .get(`${API_URL}/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      return res.data.identifiers.individual_id;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error('error: ', err);
      return err;
    });
}

export async function fetchUserInfo(userId) {
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);
  return axios
    .get(`${API_URL}/patient/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error('error: ', err);
      return err;
    });
}

export async function updateUserConsent(consents) {
  const token = localStorage.getItem('access_token');
  const userId = localStorage.getItem('user_id');

  return axios
    .patch(`${API_URL}/patient/${userId}/consent`, consents, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      return res.data;
    })
    .catch(function (err) {
      console.error('error: ', err);
      return err;
    });
}

export async function fetchUserTestRequests() {
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);
  return axios
    .get(`${API_URL}/requisition`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error('error: ', err);
      return err;
    });
}

export async function fetchRequisitionDetailsById(requisitionId) {
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);
  return axios
    .get(`${API_URL}/requisition/${requisitionId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      res.data.requisition_id = requisitionId;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      window.location.pathname = '/order/error';
      console.error('error: ', err);
      return err;
    });
}

export async function makeGeneticCounselingRequest(requestPayload) {
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);

  return axios
    .post(`${API_URL}/genetic-counseling`, requestPayload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      console.error('error: ', err);
      return err;
    });
}

export async function makeCuresActRelease(requestPayload) {
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);

  return axios
    .post(`${API_URL}/cures-release`, requestPayload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      return res.data;
    })
    .catch(function (err) {
      loader = false;
      console.error('error: ', err);
      return err;
    });
}

export async function fetchGeneticCounselingDetails() {
  const userId = sessionStorage.getItem('user_id');
  const token = localStorage.getItem('access_token');
  let loader = true;

  setTimeout(() => {
    if (loader) window.location.pathname = '/order/error';
  }, 10000);

  return axios
    .get(`${API_URL}/patient/${userId}/genetic-counseling`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (res) {
      loader = false;
      const resultsLength = res.data.results.length;
      // we want the last (most recent) element
      return res.data.results[resultsLength - 1];
    })
    .catch(function (err) {
      loader = false;
      console.error('error: ', err);
      return err;
    });
}
